import React, { useState, useRef, useEffect } from 'react';
import {
  Tr,
  Td,
  Input,
  InputGroup,
  List,
  ListItem,
  Portal,
  Box,
  Text,
  Skeleton,
  Textarea
} from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";
import { NumericFormat } from 'react-number-format';
import {
  postIsinResults,
  getIsinOngoingCharge,
} from '../../../../services/apiServices';

const PortfoliTableBody = ({
  keyIndex,
  rowDetails,
  updateUserAnsweredDetails,
}) => {
  const debounceTimeout = useRef(null);
  const wrapperRef = useRef(null);
  const [portfoilioDetails, setPortfoilioDetails] = useState(rowDetails);
  const [showIsinTable, setShowIsinTable] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchResultsEmpty, setisSearchResultsEmpty] = useState(false);
  const handleAssetTermChange = (indexname, value) => {
    setPortfoilioDetails((prevValues) => {
      return { ...prevValues, [indexname]: value }; // Create a new object with updated assetName
    });
    if (indexname === 'Asset Name') {
      setisSearchResultsEmpty(false);
      setSearchResults([]);
      setShowIsinTable(true);
      searchIsinResults(value);
    }
  };
  const handleIsinListBlur = () => {
    // setShowIsinTable(false);
  };
  // const handleFocus = () => setShowIsinTable(true); // Show dropdown on focus
  const handleBlur = () => {
    setTimeout(() => {
      setShowIsinTable(false); // Hide dropdown on blur
    }, [400]);
  };
  const handleSelectItem = async (assetname, assetIsin) => {
    setPortfoilioDetails((prevValues) => {
      return { ...prevValues, ['Asset Name']: assetname }; // Create a new object with updated assetName
    });
    setShowIsinTable(false);
    const results = await getIsinOngoingCharge(assetIsin);
    Object.entries(results).forEach(([key, value]) => {
      if (portfoilioDetails.hasOwnProperty(key)) {
        setPortfoilioDetails((prevValues) => {
          return { ...prevValues, [key]: value }; // Create a new object with updated assetName
        });
      }
    });
  };
  const searchIsinResults = async (searchterm) => {
    // Clear any previous timeout
    clearTimeout(debounceTimeout.current);

    // Set a new timeout
    debounceTimeout.current = setTimeout(async () => {
      try {
        let res = { isin: searchterm };
        const IsinResults = await postIsinResults(res); // Replace this with your actual API call
        setisSearchResultsEmpty(IsinResults.data.length > 0 ? false : true);
        setSearchResults(IsinResults.data); // Update the state with API results
      } catch (error) {
        console.error('Error fetching ISIN results:', error);
        setisSearchResultsEmpty(true);
      }
    }, 1000); // Debounce delay set to 3 seconds
  };
  useEffect(() => {
    updateUserAnsweredDetails(keyIndex, portfoilioDetails);
  }, [portfoilioDetails]);
  useEffect(() => {
    // Add event listener to detect clicks outside
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleIsinListBlur();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
      <Tr key={keyIndex} className={"row_"+keyIndex}>
        {Object.entries(portfoilioDetails).map(([key, columnValue], index) => (
          <React.Fragment key={index}>
            {key === 'Asset Name' ? (
              <Td key={index} w="20%" ref={wrapperRef} style={{ position: 'relative' }}>
                <InputGroup>
                  <Textarea
                    as={ResizeTextarea}
                    resize="none"
                    placeholder="Search for ISIN of Asset Name"
                    value={columnValue}
                    onChange={(e) => handleAssetTermChange(key, e.target.value)}
                    sx={{
                      textStyle: 'manropeHeading',
                      whiteSpace: 'pre-wrap', // Preserve whitespace and allow wrapping
                      wordWrap: 'break-word', // Break long words
                      resize: 'none', // Prevent manual resizing
                    }}
                    fontSize="14px"
                    fontWeight="400"
                    borderRadius="14px"
                    _focus={{ borderColor: 'white' }}
                    _hover={{borderColor: 'white'}}
                    height="auto" // Automatically adjusts height
                    minHeight="40px"
                    borderColor="white"
                    onBlur={handleBlur}
                    overflow="hidden"
                    pr="4rem"
                  />
                </InputGroup>
                {showIsinTable && (
                  <Portal>
                    <Box
                      position="absolute"
                      top={`${
                        wrapperRef.current?.getBoundingClientRect().bottom
                      }px`}
                      left={`${
                        (wrapperRef.current?.getBoundingClientRect().left ||
                          0) + 15
                      }px`}
                      width="320px"
                      zIndex={1500}
                      bg="white"
                      boxShadow="base"
                      borderRadius="md"
                      maxHeight="200px"
                      overflowY="auto"
                      p="10px 0px"
                    >
                      <Text
                        sx={{ textStyle: 'dmsansText' }}
                        fontSize="12px"
                        fontWeight="400"
                        color="avenirGrey.500"
                        p="0px 6px"
                        lineHeight="20px"
                        letterSpacing="-0.24px"
                        borderBottom="1px"
                        borderColor="avenirGrey.100"
                      >
                        Select an option below
                      </Text>
                      {searchResults.length ? (
                        <List>
                          {searchResults.map((item, idx) => (
                            <ListItem
                              key={idx}
                              cursor="pointer"
                              _hover={{ bg: 'gray.200' }}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents card onClick from being triggered
                                handleSelectItem(item.fundName, item.ISIN);
                              }}
                              p="1px 6px 4px 6px"
                              borderRadius="2px"
                              sx={{ textStyle: 'manropeHeading' }}
                              fontSize="14px"
                              fontWeight="400"
                            >
                              {item.fundName}
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Box>
                          {!isSearchResultsEmpty ? (
                            Array.from({ length: 3 }, (_, index) => (
                              <Skeleton
                                key={index} // Always include a key when mapping an array
                                mt="6px"
                                height="18px"
                                width="290px"
                                borderRadius="8px"
                                startColor="secondaryGray.100"
                                endColor="secondaryGray.300"
                                m="8px 10px"
                              />
                            ))
                          ) : (
                            <Text
                              sx={{ textStyle: 'manropeHeading' }}
                              fontSize="14px"
                              fontWeight="400"
                              m="8px 10px"
                            >
                              No Assets Found
                            </Text>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Portal>
                )}
              </Td>
            ) : (
              <Td w={key === 'Isin' ? '13%' : 'auto'}>
                {key !== 'Isin' ? (
                  <NumericFormat
                    key={index}
                    as={ResizeTextarea}
                    minHeight="35px"
                    resize="none"
                    value={
                      columnValue !== undefined && columnValue !== null
                        ? String(columnValue)
                        : ''
                    }
                    thousandSeparator={true}
                    suffix="%"
                    inputMode="numeric"
                    customInput={Input}
                    placeholder="100%"
                    borderRadius="14px"
                    onValueChange={(values) =>
                      handleAssetTermChange(key, values.value)
                    }
                    ml="3px"
                    _focus={{ borderColor: 'white' }}
                    borderColor="white"
                    _hover={{borderColor: 'white'}}
                  />
                ) : (
                  <Input
                    key={index}
                    value={
                      columnValue !== undefined && columnValue !== null
                        ? String(columnValue)
                        : ''
                    }
                    as={ResizeTextarea}
                    resize="none"
                    minHeight="35px"
                    onChange={(e) => handleAssetTermChange(key, e.target.value)}
                    placeholder="Enter ISIN"
                    sx={{ textStyle: 'manropeHeading' }}
                    fontSize="14px"
                    fontWeight="400"
                    borderRadius="14px"
                    _focus={{ borderColor: 'white' }}
                    borderColor="white"
                    _hover={{borderColor: 'white'}}
                  />
                )}
              </Td>
            )}
          </React.Fragment>
        ))}
      </Tr>
    </>
  );
};

export default PortfoliTableBody;
