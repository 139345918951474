/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAiAnswerGenerating,
  setCurrentReportQuestionId,
} from 'reducers/actions';
import axios from 'axios';
import { Box, Text, Flex, Progress } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
import { patchToPreviousQuestion } from '../../../services/apiServices';
//Constants
import {
  API_SUBSECTION_QUESTION,
  API_SUITABILITY_SUBSECTION_USER_ANSWER,
} from 'static/constants';
//components
import YesOrNo from './YesOrNo';
import TypeAnswer from './TypeAnswer';
import DropDownAnswer from './DropDownAnswer';
import SelectAnswer from './SelectAnswer';
import CalendarAnswer from './CalendarAnswer';
import TableAnswer from './TableAnswer';
import OrAnswer from './OrAnswer';
import PortfolioAnswer from './PortfolioAnswer';
import MultiColumnTableAnswer from './MutliColumnTableAnswer';
import ExportDocPdf from './ExportDocPdf';
import { getAuthenticationID } from 'services/apiServices';
import PortfolioTablePage from './v2Portfolio/PortfolioTablePage'
import { motion } from 'framer-motion';
export default function Default({
  updateAfterUserAnswer,
  progressValue,
  sectionDetails,
  currentSection,
  generatePdfOrDoc,
  makeScreenQuestionAreaFullWidth,
  updateAfterPreviousQuestion,
}) {
  const dispatch = useDispatch();
  const financialData = useSelector((state) => state.ai);
  const currentReportUID = useSelector((state) => state.reportUID);
  const isAiFlag = financialData;
  const [questionType, setquestionType] = useState('');
  const [question, setQuestion] = useState({});
  const userAnswered = (answer) => {
    postUserAnswer(answer);
  };
  const goToPreviousQuestion = async () => {
    dispatch(setAiAnswerGenerating({ aiAnswering: true }));
    let res = {
      questionID: question.previousQuestionID,
      reportUID: currentReportUID.reportUID,
    };
    try {
      const response = await patchToPreviousQuestion(res);
      setquestionType('');
      await getSubsectionQuestion(true);
      updateAfterPreviousQuestion(question.previousQuestionID);
    } catch (error) {
      console.error('Error while patching to previous question:', error); // Handle the error
      dispatch(setAiAnswerGenerating({ aiAnswering: true }));
    }
  };

  const componentVariants = {
    hidden: { x: '-100%', opacity: 0 }, // Start off-screen to the left
    visible: { x: 0, opacity: 1 }, // Slide into view from the left
    exit: { x: '100%', opacity: 0 }, // Slide out to the right when closing
  };
  //API Calls
  const postUserAnswer = async (answer) => {
    let authToken = await getAuthenticationID();
    const apiSubsectionQuestionUrl = API_SUITABILITY_SUBSECTION_USER_ANSWER;
    let requestBody = {
      data: answer,
      reportUID: currentReportUID.reportUID,
      questionID: question.questionID,
    };
    try {
      dispatch(setAiAnswerGenerating({ aiAnswering: true }));
      const response = await axios.post(apiSubsectionQuestionUrl, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      updateAfterUserAnswer();
      getSubsectionQuestion();
    } catch (error) {
      dispatch(setAiAnswerGenerating({ aiAnswering: false }));
      console.error('Error making the GET progress request:', error);
      throw error;
    }
  };
  const getSubsectionQuestion = async (res) => {
    let authToken = await getAuthenticationID();
    const apiSubsectionQuestionUrl = `${API_SUBSECTION_QUESTION}?reportUID=${currentReportUID.reportUID}`;
    try {
      const response = await axios.get(apiSubsectionQuestionUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      let res = {
        questionId: response.data.questionID,
        previousQuestionId: response.data.previousQuestionID,
      };
      dispatch(setCurrentReportQuestionId(res));
      setQuestion(response.data);
      let val = response.data.type;
      setquestionType(val);
      if (val === 'MultiColumnTable' || val === 'Portfolio') {
        makeScreenQuestionAreaFullWidth(true);
      } else {
        makeScreenQuestionAreaFullWidth(false);
      }
    } catch (error) {
      console.error('Error making the GET progress request:', error);
      throw error;
    }
    if (res) {
      dispatch(setAiAnswerGenerating({ aiAnswering: false }));
    }
  };
  const currentSectionName = sectionDetails.find(
    (section) => section.sectionNumber === currentSection
  )?.sectionName || "";
  useEffect(() => {
    getSubsectionQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const generatePdfOrDocQuestion = (value) => {
    generatePdfOrDoc(value);
  };
  return (
    <Box
      w="100%"
      h="100%"
      borderColor="avenirGrey.200"
      borderWidth="1px"
      borderStyle="solid"
      bg="avenirWhite"
      borderRadius="8px"
      overflowY="hidden"
    >
      <Box w="100%" zIndex="-1">
        <Progress value={progressValue} w="99%" h="6px" />
      </Box>
      {sectionDetails.length && questionType !== 'MultiColumnTable' && questionType !== 'Portfolio'? (
        <Flex p="16px 12px" justifyContent="space-between" alignItems="center">
          <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
            {currentSection !== 9999
              ? currentSectionName
              : 'Export'}
          </Text>
          <Text sx={{ textStyle: 'manropeHeading' }} fontSize="12px">
            {currentSection !== 9999 ? currentSection : sectionDetails.length}/
            {sectionDetails.length}
          </Text>
        </Flex>
      ) : null}
      <Box
        w="100%"
        p="16px 12px"
        borderRadius="0 0 8px 8px"
        h="100%"
        overflowY="hidden"
      >
        {isAiFlag.aiAnswering ? (
          <Flex gap="9px" alignItems="center">
            <BsThreeDots style={{ fontSize: '24px' }} color="#CEA2FC" />
            <Text
              sx={{ textStyle: 'manropeHeading' }}
              color="avenirSuitabilityColor.300"
            >
              Avenir AI is Processing
            </Text>
          </Flex>
        ) : (
          <Box h={questionType === 'MultiColumnTable' || questionType === 'Portfolio' ? "100%" : "90%"} overflowY={questionType === 'MultiColumnTable'|| questionType === 'Portfolio' ? "auto" : "hidden"}>
            {questionType === 'YesOrNo' ? (
              <YesOrNo question={question} userAnswered={userAnswered} />
            ) : null}
            {questionType === 'Open' ? (
              <TypeAnswer
                question={question}
                userAnswered={userAnswered}
                goToPreviousQuestion={goToPreviousQuestion}
              />
            ) : null}
            {questionType === 'Dropdown' ? <DropDownAnswer /> : null}
            {questionType === 'MCQ' ? (
              <SelectAnswer
                question={question}
                userAnswered={userAnswered}
                goToPreviousQuestion={goToPreviousQuestion}
              />
            ) : null}
            {questionType === 'calendar' ? <CalendarAnswer /> : null}
            {questionType === 'Table' ? (
              <TableAnswer
                question={question}
                userAnswered={userAnswered}
                goToPreviousQuestion={goToPreviousQuestion}
              />
            ) : null}
            {questionType === 'Or' ? (
              <OrAnswer question={question} userAnswered={userAnswered} />
            ) : null}
            {questionType === 'Portfolio' ? (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={componentVariants}
                transition={{ duration: 0.9 }} // Duration of the transition
              >
                <PortfolioTablePage
                  question={question}
                  userAnswered={userAnswered}
                  goToPreviousQuestion={goToPreviousQuestion}
                />
              </motion.div>
            ) : null}
            {questionType === 'Export' ? (
              <ExportDocPdf
                question={question}
                userAnswered={userAnswered}
                generatePdfOrDocQuestion={generatePdfOrDocQuestion}
                goToPreviousQuestion={goToPreviousQuestion}
              />
            ) : null}
            {questionType === 'MultiColumnTable' ? (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={componentVariants}
                transition={{ duration: 0.9 }} // Duration of the transition
              >
                <MultiColumnTableAnswer
                  question={question}
                  userAnswered={userAnswered}
                  goToPreviousQuestion={goToPreviousQuestion}
                />
              </motion.div>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
}
