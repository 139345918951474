import React, { useEffect, useState } from 'react';
import { Box, Text, Button, Flex, Icon } from '@chakra-ui/react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { IoArrowBack } from "react-icons/io5";

const PortfolioExcelImport = ({ UpdatePortfolioData, question, updateTemplateState }) => {
  const [isOpen, setIsOpen] = useState(false);
  // Fields definition for column mapping and validation
  const [fields, setFields] = useState([]);

  // Custom hooks for validation and data transformation
  const rowHook = (data, addError) => {
    // Example validation: No negative weight allowed
    if (Number(data.weight) < 0) {
      addError('weight', {
        message: 'Weight cannot be negative',
        level: 'error',
      });
    }
    return data;
  };
  const goback = () =>{
    updateTemplateState(false)
  }
  const handleOnSubmit = (data) => {
    UpdatePortfolioData(data.validData);
    setIsOpen(false); // Close the modal after submitting
  };
  useEffect(() => {
    let Inputfields = [];
    question.answers[0].PortfolioColumnNames.forEach((columnName) => {
      let res = {};
      // Use proper key-value assignment
      res['label'] = columnName;
      res['key'] = columnName;
      res['fieldType'] = { type: 'input' };
      if (columnName === 'Asset Name') {
        res['validations'] = [
          { rule: 'required', errorMessage: `${columnName} is required` },
        ];
      }
      Inputfields.push(res); // Push the object into the fields array
    });
    setFields(Inputfields);
  }, [question]);
  return (
    <Box p={5} h="40vh">
      <Flex  w="100%" justifyContent="space-between">
        <Text
          sx={{ textStyle: 'dmsansHeading' }}
          color="#2B3674"
          fontSize="20px"
          fontWeight="700"
          mb="10px"
        >
          Portfolio Excel Import
        </Text>
        <Button
          onClick={goback}
          bg="white"
          color="avenirSuitabilityColor.600"
          p="8px 18px"
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          _hover={{ bg: 'white' }}
          border="1px solid"
          borderColor="avenirSuitabilityColor.600"
          borderRadius="12px"
        >
          {<IoArrowBack style={{ fontSize: '24px', marginRight: '10px' }} />}
          Back
        </Button>
      </Flex>
      <Flex
        bg="white"
        border="1px dashed #E0E5F2"
        borderRadius="13px"
        p="10px"
        height="100%"
        spacing={4}
        textAlign="center"
        backgroundColor="#FAFCFE"
        cursor="pointer"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        h="100%"
        onClick={() => setIsOpen(true)}
      >
        <Icon as={MdOutlineCloudUpload} color="#4E13C3" boxSize="50px" />
        <Text
          sx={{ textStyle: 'dmsansHeading' }}
          color="#8F9BBA"
          fontSize="12px"
          fontWeight="500"
          letterSpacing="-0.24px"
          lineHeight="20px"
          mt="20px"
        >
          Upload .xlsx, .xls or .csv file
        </Text>
        <Flex
          as="span"
          sx={{ textStyle: 'dmsansHeading' }}
          fontSize="18px"
          fontWeight="700"
          letterSpacing="-0.36px"
          lineHeight="28px"
          flexDirection="column"
        >
          <Button
            bg="avenirSuitabilityColor.600"
            color="white"
            p="8px 18px"
            sx={{ textStyle: 'manropeHeading' }}
            fontSize="14px"
            _hover={{ bg: 'avenirSuitabilityColor.600' }}
            borderRadius="12px"
            w="250px"
            mt="20px"
          >
            Import Portfolio from Excel
          </Button>
        </Flex>
      </Flex>

      {/* ReactSpreadsheetImport Component */}
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={handleOnSubmit}
        fields={fields}
        rowHook={rowHook}
        customTheme={{
          colors: {
            rsi: {
              500: '#4E13C3', // Custom blue color
            },
          },
          components: {
            Button: {
              baseStyle: {
                borderRadius: '12px',
                padding: '8px 18px',
              },
            },
          },
        }}
        translations={{
          uploadStep: {
            title: 'Upload Portfolio Data',
            dropzoneTitle: 'Drag and drop your file here',
          },
        }}
      />
    </Box>
  );
};

export default PortfolioExcelImport;
