import axios from './configAxios'
import {
  API_SUITABILITY_PROGRESS,
  API_CREATE_SUITABILITY_REPORT,
  API_INITIATE_SUITABILITY_REPORT,
  API_SECTIONS_URL_BASE,
  API_SUITABILITY_SERVER_ANSWER,
  API_SUITABILITY_HEADERS_FOOTER,
  API_SUITABILITY_ISIN_SEARCH,
  API_SUITABILITY_UPLOAD_REPORT,
  API_SUITABILITY_UPLOAD_STATE,
  API_SUITABILITY_CREATE_CONTEXT,
  API_SUITABILITY_GET_CONTEXT,
  API_SUITABILITY_UPLOAD_TEXT_REPORT,
  API_SUITABILITY_UPLOAD_PDF_SECTION_DETAILS,
  API_SUITABILITY_REMOVE_PDF_SECTION_FILE,
  API_SUITABILITY_GET_CLIENTS_DETAILS,
  API_SUITABILITY_GET_USER_FOOTER_DETAILS,
  API_SUIRABILITY_POST_USER_FOOTER_DETAILS,
  API_SUITABILITY_POST_USER_HEADER_DETAILS,
  API_SUITABILITY_GET_USER_HEADER_DETAILS,
  API_SUITABILITY_CREATE_USER,
  API_SUTIABILITY_GET_REPORT_LIST,
  API_SUITABILITY_GET_CLIENTS_LIST,
  API_SUITABILITY_CREATE_CLIENT,
  API_SUITABILITY_STRIPE_CHECKOUT_LINK,
  API_SUITABILITY_STRIPE_PROTAL_LINK,
  API_SUITABILITY_SFDT_HEADER_DETAILS,
  API_SUITABILITY_FUND_ONGOING_CHARGE,
  API_SUITABILITY_REPORT_EXPORT_DOC,
  API_SUITABILITY_REPORT_EXPORT_PDF,
  API_SUITBILITY_REPORT_GET_USER_COLOR,
  API_SUITABILITY_REPORT_POST_USER_COLOR,
  API_SUITABLILITY_REPORT_USER_CAN_CREATE_REPORT,
  API_SUITABILITY_REPORT_TEMPLATE_LIST,
  API_SUITABILITY_MICORSOFT_MEETINGS,
  API_SUITABILITY_MICTOSOFT_GET_TRANSCTIPT,
  API_SUITABILITY_MICROSOFT_DELETE_MEETING,
  API_SUITABILITU_REPORT_EXPORT_XLS,
  API_SUBSECTION_QUESTION
} from 'static/constants';  
//firebase imports
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../firebaseFiles/firebase-config';
import { doc, getDoc, getFirestore, setDoc, updateDoc  } from "firebase/firestore";
//get authentication ID
let U_ID = ''
const getAuthenticationID = () => {
  const auth = getAuth(app);
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        U_ID = user.uid
        user
          .getIdToken(true)
          .then((idToken) => {
            resolve(idToken); // Resolve the promise with the ID token
          })
          .catch((error) => {
            console.error(error);
            reject(error); // Reject the promise if there is an error
          });
      } else {
        console.log('No user is currently signed in.');
        reject('No user is currently signed in.'); // Reject the promise if no user is signed in
      }
    });
  });
};
const firebaseLogout = () => {
  const auth = getAuth(app);
  auth
    .signOut()
    .then(() => {
      console.log('User signed out');
      // Optionally, you can redirect the user to a login page or home page
      // window.location.href = '/login';
    })
    .catch((error) => {
      console.error('Error signing out:', error);
    });
};
const setNumberOfReportsInitiated = async () =>{
  const db = getFirestore();
  const docRef = doc(db, "SuitabilityUsers", U_ID);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const docData = docSnap.data();
      const newReportCount = docData.ReportInitiated ? docData.ReportInitiated + 1 : 1;
      await updateDoc(docRef, { ReportInitiated: newReportCount });
      return newReportCount;
    } else {
      await setDoc(docRef, { ReportInitiated: 1 }, { merge: true });
      return 1;
    }
  } catch (error) {
    console.error("Error updating document:", error);
    return null;
  }
}
// async function to call the api using the axios like this
const InitiateReportAPI = async (res) => {
  let authToken = await getAuthenticationID();
  const apiInitiateUrl = API_INITIATE_SUITABILITY_REPORT;
  try {
    const response = await axios.post(apiInitiateUrl, res, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//Api to call the create report
const CreateReportAPI = async (res, mid) => {
  let authToken = await getAuthenticationID();
  const apiInitiateUrl = API_CREATE_SUITABILITY_REPORT;
  try {
    const response = await axios.post(apiInitiateUrl, res, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
        ...(mid && { MicrosoftAuthorization: mid }), 
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      // Return error response to handle it in the calling function
      return { status: 409, existingReport: true };
    } else {
      console.error('Error making the create Report request:', error);
      throw error;
    }
  }
};
const getSectionInReportAPI = async (res) => {
  let authToken = await getAuthenticationID();
  const apiSectionsBaseUrl = API_SECTIONS_URL_BASE;
  try {
    // Append the reportName as a query parameter
    const url = `${apiSectionsBaseUrl}?reportUID=${res}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the GET Sections request:', error);
    throw error;
  }
};
const getSuitabilityProgressAPI = async (res) => {
  let authToken = await getAuthenticationID();
  const apiSectionsBaseUrl =`${API_SUITABILITY_PROGRESS}?reportUID=${res}`;
  try {
    // Append the reportName as a query parameter
    const response = await axios.get(apiSectionsBaseUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the GET progress request:', error);
    throw error;
  }
};
const getServerAnswerAPI = async (res) => {
  let authToken = await getAuthenticationID();
  const apiServerAnsweUrl = `${API_SUITABILITY_SERVER_ANSWER}?reportUID=${res}`;
  try {
    // Append the reportName as a query parameter
    const response = await axios.get(apiServerAnsweUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the GET progress request:', error);
    throw error;
  }
};
///get header and footer data for the syncfusion editor
const getHeaderAndFooterData = async () => {
  let authToken = await getAuthenticationID();
  const apiHeaderFooterUrl = API_SUITABILITY_HEADERS_FOOTER;
  try {
    const response = await axios.get(apiHeaderFooterUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the GET progress request:', error);
    throw error;
  }
};
//RESET REPORT
const getResetReport = async (res) => {
  let authToken = await getAuthenticationID();
  const apiHeaderFooterUrl = `${API_CREATE_SUITABILITY_REPORT}?reportUID=${res}`;
  try {
    const response = await axios.delete(apiHeaderFooterUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the GET progress request:', error);
    throw error;
  }
};
//ISIN SEARCH
const postIsinResults = async (responseBody) => {
  let authToken = await getAuthenticationID();
  const apiIsiinResults = API_SUITABILITY_ISIN_SEARCH;
  try {
    const response = await axios.post(apiIsiinResults, responseBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//Upload Report
const postUploadReport = async (data) => {
  let authToken = await getAuthenticationID();
  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('pdfFile', data.pdfFile);
  formData.append('category',data.title);
  // data.pdfFile.forEach(file => {
  //   formData.append('pdfFile', file);
  // });
  formData.append('reportUID', data.reportUID);
  try {
    const response = await axios.post(API_SUITABILITY_UPLOAD_REPORT, formData, {
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data; // Return the response data if needed elsewhere
  } catch (error) {
    if (error.response) {
      // Return server response for status codes outside 2xx (e.g., 403, 500)
      return "CANNOT_READ"
    } else {
      // Return error if no response was received or other errors occurred
      console.error('Error:', error.message);
      let res = "CANNOT_READ"
      return { error: 'An error occurred', res };
    }
  }
};
//post Server Answer
const postSyncEditorAnswer = async (responseBody) => {
  let authToken = await getAuthenticationID();
  const apiIsiinResults = API_SUITABILITY_UPLOAD_STATE;
  try {
    const response = await axios.post(apiIsiinResults, responseBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//get sync Editor Store
const getSyncEditorAnswer = async (res) => {
  let authToken = await getAuthenticationID();
  const apiSectionsBaseUrl =`${API_SUITABILITY_GET_CONTEXT}?reportUID=${res}`;
  try {
    // Append the reportName as a query parameter
    const response = await axios.get(apiSectionsBaseUrl, {
      headers: {
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the GET Sections request:', error);
    throw error;
  }
};
// call context api
const postCreateContext = async (responseBody) => {
  let authToken = await getAuthenticationID();
  const apiIsiinResults = API_SUITABILITY_CREATE_CONTEXT;
  try {
    const response = await axios.post(apiIsiinResults, responseBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    setNumberOfReportsInitiated()
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
// post user text context
const postReportText = async (res) => {
  let authToken = await getAuthenticationID();
  const apiInitiateUrl = API_SUITABILITY_UPLOAD_TEXT_REPORT;
  try {
    const response = await axios.post(apiInitiateUrl, res, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
// Get Details of the upload pdf sections
const getUploadPdfDetails = async (val) => {
  let authToken = await getAuthenticationID();
  const apiSectionsBaseUrl = `${API_SUITABILITY_UPLOAD_PDF_SECTION_DETAILS}?templateUID=${val}`;
  try {
    // Append the reportName as a query parameter
    const response = await axios.get(apiSectionsBaseUrl, {
      headers: {
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the GET Sections request:', error);
    throw error;
  }
};
// Remove Pdf for sections in upload files section
const postRemoveSectionPdf = async (res) => {
  let authToken = await getAuthenticationID();
  const apiInitiateUrl = `${API_SUITABILITY_REMOVE_PDF_SECTION_FILE}?reportUID=${res.reportUID}&title=${res.title}&category=${res.category}`;
  try {
    const response = await axios.delete(apiInitiateUrl,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//GET client detial to show in navbar
const getClientDetails = async() => {
  let authToken = await getAuthenticationID();
  const apiSectionsBaseUrl = API_SUITABILITY_GET_CLIENTS_DETAILS;
  try {
    // Append the reportName as a query parameter
    const response = await axios.get(apiSectionsBaseUrl, {
      headers: {
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the GET Sections request:', error);
    throw error;
  }
}
//GET footer details of user
const getClientFooterDetails = async() => {
  let authToken = await getAuthenticationID();
  const apiClientFooterUrl = API_SUITABILITY_GET_USER_FOOTER_DETAILS;
  try {
    const response = await axios.get(apiClientFooterUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//UPDATE footer details of user
const postClientFooterDetails = async (res) => {
  let authToken = await getAuthenticationID();
  const apiInitiateUrl = API_SUIRABILITY_POST_USER_FOOTER_DETAILS;
  try {
    const response = await axios.post(apiInitiateUrl, res, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//UPDATE header details of user
const postClientHeadetDetails = async (res) => {
  let authToken = await getAuthenticationID();
  const apiInitiateUrl = API_SUITABILITY_POST_USER_HEADER_DETAILS;
  try {
    const response = await axios.post(apiInitiateUrl, res, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//GET HEADER details of user
const getClienHeaderDetails = async() => {
  let authToken = await getAuthenticationID();
  const apiClientHeaderUrl = API_SUITABILITY_GET_USER_HEADER_DETAILS;
  try {
    const response = await axios.get(apiClientHeaderUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
// CREATE user 
const createUser = async (res) => {
  let authToken = await getAuthenticationID();
  const apiCreateUser = API_SUITABILITY_CREATE_USER;
  try {
    const response = await axios.post(apiCreateUser, res, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//GET get User Report List
const getUserReportList = async(page, resultsPerPage, searchTerm) => {
  let authToken = await getAuthenticationID();
  let apiUserReportUrl = ''
  if(searchTerm){
     apiUserReportUrl = `${API_SUTIABILITY_GET_REPORT_LIST}?page=${page}&resultsPerPage=${resultsPerPage}&search=${searchTerm}`;
  } else{
     apiUserReportUrl = `${API_SUTIABILITY_GET_REPORT_LIST}?page=${page}&resultsPerPage=${resultsPerPage}`;
  }
  try {
    const response = await axios.get(apiUserReportUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//GET get User clients List
const getUserClientList = async(clientName, results) => {
  let authToken = await getAuthenticationID();
  const apiUsersClientListUrl = `${API_SUITABILITY_GET_CLIENTS_LIST}?clientName=${clientName}&results=${results}`;
  try {
    const response = await axios.get(apiUsersClientListUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
// CREATE Client 
const createClient = async (res) => {
  let authToken = await getAuthenticationID();
  const apiCreateClient = API_SUITABILITY_CREATE_CLIENT;
  try {
    const response = await axios.post(apiCreateClient, res, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//GET stripe checkout link 
const getStripeCheckoutLink = async(res) => {
  let authToken = await getAuthenticationID();
  const apiGetStripeLink = `${API_SUITABILITY_STRIPE_CHECKOUT_LINK}?subscriptionType=${res}`;
  try {
    const response = await axios.get(apiGetStripeLink, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
// GET stripe protal link 
const getStripePortalLink = async () => {
  let authToken = await getAuthenticationID();
  const apiProtalLink = API_SUITABILITY_STRIPE_PROTAL_LINK;
  try {
    const response = await axios.get(apiProtalLink, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//GET get User header sfdt
const getUserSfdtHeader = async(clientName, results) => {
  let authToken = await getAuthenticationID();
  const apiUsersClientListUrl = API_SUITABILITY_SFDT_HEADER_DETAILS;
  try {
    const response = await axios.get(apiUsersClientListUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
//GET get Isins ongoing charge
const getIsinOngoingCharge = async(val) => {
  let authToken = await getAuthenticationID();
  const apiIsinSearchCharge = `${API_SUITABILITY_FUND_ONGOING_CHARGE}?isin=${val}`;
  try {
    const response = await axios.get(apiIsinSearchCharge, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    let res ={
      "isin": "",
      "ongoing_charge": ""
  }
    return res;
  }
};
//GET export doc for the reports
const getReportExportDoc = async(reportID) => {
  let authToken = await getAuthenticationID();
  const apiExportDoc = `${API_SUITABILITY_REPORT_EXPORT_DOC}?reportUID=${reportID}`;
  try {
    const response = await axios.get(apiExportDoc, {
      headers: {
        Authorization: authToken,
      },
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('Error making the export doc Report request:', error);
  }
};
const getReportExportPDF = async (reportID) => {
  let authToken = await getAuthenticationID();
  const apiExportPDF = `${API_SUITABILITY_REPORT_EXPORT_PDF}?reportUID=${reportID}`;
  try {
    const response = await axios.get(apiExportPDF, {
      headers: {
        Authorization: authToken,
      },
      responseType: 'blob', // Ensure the response is handled as a Blob
    });
    return response.data;
  } catch (error) {
    console.error('Error making the export PDF Report request:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
const getUserColor = async (reportID) => {
  let authToken = await getAuthenticationID();
  const apiUserColor = API_SUITBILITY_REPORT_GET_USER_COLOR;
  try {
    const response = await axios.get(apiUserColor, {
      headers: {
        Authorization: authToken,
      },
      responseType: 'application/json',
    });
    return response.data;
  } catch (error) {
    console.error('Error making the get user color of Report request:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
// POST client color
const postUserColor = async (res) => {
  let authToken = await getAuthenticationID();
  const apiUserColor = API_SUITABILITY_REPORT_POST_USER_COLOR;
  try {
    const response = await axios.post(apiUserColor, res, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the Initiate Report request:', error);
    throw error;
  }
};
const getCanUserCreateReport = async () => {
  let authToken = await getAuthenticationID();
  const apiCreateReport = API_SUITABLILITY_REPORT_USER_CAN_CREATE_REPORT;
  try {
    const response = await axios.get(apiCreateReport, {
      headers: {
        Authorization: authToken,
      },
      responseType: 'application/json',
    });
    return response.data;
  } catch (error) {
    console.error('Error making the get user color of Report request:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
const getTempalteList = async () => {
  let authToken = await getAuthenticationID();
  const apiTemplateList = API_SUITABILITY_REPORT_TEMPLATE_LIST;
  try {
    const response = await axios.get(apiTemplateList, {
      headers: {
        Authorization: authToken,
        responseType: 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error making the get template request:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
const getMicrosoftMeetings = async (res, meetingParam) => {
  let authToken = await getAuthenticationID();
  let mAToken = res
  const apiCreateReport = API_SUITABILITY_MICORSOFT_MEETINGS;
  try {
    const response = await axios.get(apiCreateReport, {
      headers: {
        Authorization: authToken,
        MicrosoftAuthorization: mAToken,
        responseType: 'application/json',
      },
      params: meetingParam
    });
    return response.data;
  } catch (error) {
    console.error('Error making the get user color of Report request:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
const getMicrosfotMeetingTranscript = async (res, meetingParam) => {
  let authToken = await getAuthenticationID();
  let mAToken = res
  const apiCreateReport = API_SUITABILITY_MICTOSOFT_GET_TRANSCTIPT;
  try {
    const response = await axios.get(apiCreateReport, {
      headers: {
        Authorization: authToken,
        MicrosoftAuthorization: mAToken,
        responseType: 'blob',
      },
      params: meetingParam
    });
    return response.data;
  } catch (error) {
    console.error('Error making the get user color of Report request:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
const deleteMeetingSummary = async (res, responseBody) => {
  let authToken = await getAuthenticationID();
  let mAToken = res
  const apiCreateReport = API_SUITABILITY_MICROSOFT_DELETE_MEETING;
  try {
    const response = await axios.post(apiCreateReport, responseBody, {
      headers: {
        Authorization: authToken,
        MicrosoftAuthorization: mAToken,
        responseType: 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error making the get user color of Report request:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
//GET export XLS for the reports
const getReportExportXls = async(reportID) => {
  let authToken = await getAuthenticationID();
  const apiExportDoc = `${API_SUITABILITU_REPORT_EXPORT_XLS}?reportUID=${reportID}`;
  try {
    const response = await axios.get(apiExportDoc, {
      headers: {
        Authorization: authToken,
      },
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    console.error('Error making the export xls Report request:', error);
  }
};
const patchToPreviousQuestion = async (responseBody) => {
  let authToken = await getAuthenticationID();
    const apiSubsectionQuestionUrl = API_SUBSECTION_QUESTION;
    try {
      const response = await axios.patch(apiSubsectionQuestionUrl, responseBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      return response.data
    } catch (error) {
      console.error('Error making the GET progress request:', error);
      throw error;
    }
}
export {
  InitiateReportAPI,
  CreateReportAPI,
  getSectionInReportAPI,
  getSuitabilityProgressAPI,
  getServerAnswerAPI,
  getHeaderAndFooterData,
  getResetReport,
  postIsinResults,
  postUploadReport,
  postSyncEditorAnswer,
  getAuthenticationID,
  postCreateContext,
  getSyncEditorAnswer,
  postReportText,
  firebaseLogout,
  getUploadPdfDetails,
  postRemoveSectionPdf,
  getClientDetails,
  getClientFooterDetails,
  postClientFooterDetails,
  postClientHeadetDetails,
  getClienHeaderDetails,
  createUser,
  getUserReportList,
  getUserClientList,
  createClient,
  getStripeCheckoutLink,
  getStripePortalLink,
  getUserSfdtHeader,
  getIsinOngoingCharge,
  getReportExportDoc,
  getReportExportPDF,
  getUserColor,
  postUserColor,
  getCanUserCreateReport,
  getTempalteList,
  getMicrosoftMeetings,
  getMicrosfotMeetingTranscript,
  deleteMeetingSummary,
  getReportExportXls,
  patchToPreviousQuestion
};
